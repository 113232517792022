/* NotoSansKR */
@font-face {
  font-family: "NotoSansKR";
  font-weight: 100;
  font-style: normal;
  src: url("../font/NotoSansKR-Thin.eot");
  src: url("../font/NotoSansKR-Thin.eot?#iefix") format("embedded-opentype"), url("../font/NotoSansKR-Thin.woff2") format("woff2"), url("../font/NotoSansKR-Thin.woff") format("woff"), url("../font/NotoSansKR-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "NotoSansKR";
  font-weight: 300;
  font-style: normal;
  src: url("../font/NotoSansKR-Light.eot");
  src: url("../font/NotoSansKR-Light.eot?#iefix") format("embedded-opentype"), url("../font/NotoSansKR-Light.woff2") format("woff2"), url("../font/NotoSansKR-Light.woff") format("woff"), url("../font/NotoSansKR-Light.ttf") format("truetype");
}
@font-face {
  font-family: "NotoSansKR";
  font-weight: 400;
  font-style: normal;
  src: url("../font/NotoSansKR-Regular.eot");
  src: url("../font/NotoSansKR-Regular.eot?#iefix") format("embedded-opentype"), url("../font/NotoSansKR-Regular.woff2") format("woff2"), url("../font/NotoSansKR-Regular.woff") format("woff"), url("../font/NotoSansKR-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "NotoSansKR";
  font-weight: 500;
  font-style: normal;
  src: url("../font/NotoSansKR-Medium.eot");
  src: url("../font/NotoSansKR-Medium.eot?#iefix") format("embedded-opentype"), url("../font/NotoSansKR-Medium.woff2") format("woff2"), url("../font/NotoSansKR-Medium.woff") format("woff"), url("../font/NotoSansKR-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "NotoSansKR";
  font-weight: 700;
  font-style: normal;
  src: url("../font/NotoSansKR-Bold.eot");
  src: url("../font/NotoSansKR-Bold.eot?#iefix") format("embedded-opentype"), url("../font/NotoSansKR-Bold.woff2") format("woff2"), url("../font/NotoSansKR-Bold.woff") format("woff"), url("../font/NotoSansKR-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "NotoSansKR";
  font-weight: 900;
  font-style: normal;
  src: url("../font/NotoSansKR-Black.eot");
  src: url("../font/NotoSansKR-Black.eot?#iefix") format("embedded-opentype"), url("../font/NotoSansKR-Black.woff2") format("woff2"), url("../font/NotoSansKR-Black.woff") format("woff"), url("../font/NotoSansKR-Black.ttf") format("truetype");
}
.comm-txt-4 {
  font-size: 14px;
  font-weight: 400;
}

.comm-txt-5 {
  font-size: 14px;
  font-weight: 500;
}

.m-txt-4 {
  font-size: 16px;
  font-weight: 400;
}

body {
  --color-gray: #939393;
  --color-lightGray: #637381;
  --color-green: #3BC996;
  --color-darkGreen: #3bc394;
}

body {
  background: #F1F4F5;
}
body::-webkit-scrollbar, body textarea::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
body::-webkit-scrollbar-thumb, body textarea::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 50px;
}
body::-webkit-scrollbar-track, body textarea::-webkit-scrollbar-track {
  background: #fafafa;
}

.inner {
  padding: 0 18px;
}

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flex-ac {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.flex-jc {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.flex-js {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.w100 {
  width: 100%;
}

.align_left {
  text-align: left !important;
}

.btn_ty01 {
  height: 40px;
  line-height: 40px;
  display: inline-block;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background: var(--color-green);
  text-align: center;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  border-radius: 5px;
}
@media all and (max-width: 991px) {
  .btn_ty01:not(.btn_search) {
    height: 35px;
    line-height: 34px;
    padding: 0 10px;
  }
}
.btn_ty01:hover {
  background-color: var(--color-darkGreen);
}
.btn_ty01.bBtn {
  height: 48px;
  line-height: 48px;
  max-width: 185px;
  font-size: 15px;
}
.btn_ty01.cancel {
  background: #F5F5F5;
  color: #323C47;
}
.btn_ty01.cancel:hover {
  background-color: #f1f1f1;
}
.btn_ty01.gray {
  background: #f5f5f5;
  color: #4F4F4F;
}
.btn_ty01.btn_bg {
  padding: 0 14px 0 40px;
  background-repeat: no-repeat;
  background-position: left 14px center;
}
@media all and (max-width: 991px) {
  .btn_ty01.btn_bg {
    padding-left: 35px;
  }
}
.btn_ty01.add {
  background-image: url(../img/icon/add.svg);
}
.btn_ty01.give {
  background-image: url(../img/icon/add.svg);
}
.btn_ty01.accu {
  background-image: url(../img/icon/add.svg);
}
.btn_ty01.mod {
  background-image: url(../img/icon/edit.svg);
  background-color: #41A4FF;
}
.btn_ty01.mod:hover {
  background-color: #2795fc;
}
.btn_ty01.can {
  background-image: url(../img/icon/cancel.svg);
  background-color: #B5B5B5;
}
.btn_ty01.can:hover {
  background-color: #ada7a7;
}
.btn_ty01.res {
  background-image: url(../img/icon/restoration.svg);
  background-color: #2D77E7;
}
.btn_ty01.res:hover {
  background-color: #266dd9;
}
.btn_ty01.del {
  background-image: url(../img/icon/trash.svg);
  background-color: var(--color-lightGray);
}
.btn_ty01.del:hover {
  background-color: #586571;
}
.btn_ty01.down {
  position: relative;
  background-image: url(../img/icon/download.svg);
  background-color: #fff;
  border: 1px solid #207355;
  color: #207355;
  vertical-align: middle;
}
.btn_ty01.down:hover {
  background-image: url(../img/icon/download_white.svg);
  background-color: #207355;
  color: #fff;
}
.btn_ty01.down #tableToExcelBtn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.bottom_btn_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 52px 0 80px;
}
.bottom_btn_wrap .btn_ty01 {
  max-width: 185px;
  width: 100%;
  height: 48px;
  line-height: 48px;
  margin-right: 20px;
}
.bottom_btn_wrap .btn_ty01:last-child {
  margin-right: 0;
}
@media all and (max-width: 480px) {
  .bottom_btn_wrap .btn_ty01 {
    width: 100%;
    height: 45px;
    line-height: 45px;
    margin-right: 10px;
  }
}
@media all and (max-width: 768px) {
  .bottom_btn_wrap {
    padding: 60px 0 30px;
  }
}

.btn_sort {
  width: 20px;
  height: 20px;
  background: url(../img/icon/sort.svg) no-repeat center/20px;
  vertical-align: sub;
}

.setting_btn_wrap .btn_ty01 {
  margin-left: 13px;
}
@media all and (max-width: 768px) {
  .setting_btn_wrap .btn_ty01 {
    margin-left: 4px;
    font-size: 0;
  }
}

.check_type input {
  width: 0;
  height: 0;
  position: absolute;
  padding: 0;
  margin: 0;
  overflow: hidden;
  font-size: 0;
  text-indent: -99999999px;
  border: 0;
}
.check_type input:checked ~ span::before {
  content: "";
  background: url(../img/icon/check.svg) no-repeat center, #3BC996;
  color: #fff;
  border-color: var(--color-green);
}
.check_type span {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-lightGray);
}
.check_type span::before {
  content: "";
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: sub;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: #fff;
  border: 1px solid var(--color-green);
  border-radius: 4px;
  color: var(--color-gray);
  text-align: center;
}
.check_type label {
  display: inline-block;
  font-size: 14px;
  color: #323232;
  cursor: pointer;
}
.check_type.radio label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 16px;
}
@media all and (max-width: 768px) {
  .check_type.radio label {
    font-size: 14px;
  }
}
.check_type.radio label span {
  padding-left: 35px;
}
.check_type.radio label span::before {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.check_type.radio label span::after {
  content: "";
  width: 14px;
  height: 14px;
  display: none;
  position: absolute;
  top: 50%;
  left: 5px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: var(--color-green);
  border-radius: 50%;
}
@media all and (max-width: 1280px) {
  .check_type.radio label span::after {
    width: 10px;
    height: 10px;
    left: 4px;
  }
}
@media all and (max-width: 1280px) {
  .check_type.radio label span {
    padding-left: 30px;
  }
}
.check_type.radio input:checked ~ span::before {
  border-width: 2px;
  background-color: #fff;
}
.check_type.radio input:checked ~ span::after {
  display: block;
}
.check_type.radio span {
  vertical-align: text-bottom;
}
.check_type.radio span::before {
  width: 24px;
  height: 24px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-color: #E0E2E7;
  border-radius: 50%;
}
@media all and (max-width: 1280px) {
  .check_type.radio span::before {
    width: 18px;
    height: 18px;
  }
}
.check_type:last-child {
  margin-bottom: 0;
}

.radio_group .tit {
  display: inline-block;
}
@media all and (max-width: 991px) {
  .radio_group .tit {
    display: block;
  }
}
.radio_group .check_type.radio {
  display: inline-block;
  margin: 0 10% 5px 0;
}

.radio_wrap {
  width: 100%;
  position: relative;
}

.input_ty01 label {
  font-size: 12px;
  color: #323232;
}
.input_ty01 input {
  width: 100%;
  display: block;
  padding: 8px 0;
  font-size: 14px;
  border-bottom: 1px solid #B7B7B7;
}
.input_ty01 input:not(:-moz-read-only):focus {
  border-color: var(--color-green);
}
.input_ty01 input:not(:read-only):focus {
  border-color: var(--color-green);
}
.input_ty01 input::-webkit-input-placeholder {
  color: #B7B7B7;
}
.input_ty01 input::-moz-placeholder {
  color: #B7B7B7;
}
.input_ty01 input:-ms-input-placeholder {
  color: #B7B7B7;
}
.input_ty01 input::-ms-input-placeholder {
  color: #B7B7B7;
}
.input_ty01 input::placeholder {
  color: #B7B7B7;
}
.input_ty01.select_input input:focus {
  border-color: #B7B7B7;
}

.input_ty02 a,
.input_ty02 input,
.input_ty02 textarea {
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  font-size: 14px;
  background: #fff;
  border: 1px solid #E0E2E7;
  border-radius: 5px;
}
.input_ty02 a::-webkit-input-placeholder, .input_ty02 input::-webkit-input-placeholder, .input_ty02 textarea::-webkit-input-placeholder {
  color: #BEC8D0;
}
.input_ty02 a::-moz-placeholder, .input_ty02 input::-moz-placeholder, .input_ty02 textarea::-moz-placeholder {
  color: #BEC8D0;
}
.input_ty02 a:-ms-input-placeholder, .input_ty02 input:-ms-input-placeholder, .input_ty02 textarea:-ms-input-placeholder {
  color: #BEC8D0;
}
.input_ty02 a::-ms-input-placeholder, .input_ty02 input::-ms-input-placeholder, .input_ty02 textarea::-ms-input-placeholder {
  color: #BEC8D0;
}
.input_ty02 a::placeholder,
.input_ty02 input::placeholder,
.input_ty02 textarea::placeholder {
  color: #BEC8D0;
}
@media all and (max-width: 991px) {
  .input_ty02 a,
  .input_ty02 input,
  .input_ty02 textarea {
    font-size: 14px;
  }
}
@media all and (max-width: 768px) {
  .input_ty02 a,
  .input_ty02 input,
  .input_ty02 textarea {
    padding: 8px 12px;
  }
}

.select_input {
  width: 100%;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}
.select_input::before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 18px;
  background: url(../img/icon/angle_down.svg);
}
@media all and (max-width: 768px) {
  .select_input::before {
    width: 15px;
    height: 15px;
    top: 13px;
    right: 10px;
    background-size: cover;
  }
}
@media all and (max-width: 480px) {
  .select_input::before {
    top: 15px;
  }
}
.select_input input {
  padding-right: 30px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}
.select_input.active::before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.select_input.active .select_box {
  opacity: 1;
  visibility: visible;
}
.select_input.active .select_box:empty {
  visibility: hidden;
}

.select_box {
  width: 100%;
  height: auto;
  max-height: 240px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
  overflow-y: auto;
  margin: 5px 0 5px 0;
  background: #fff;
  -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 4px 11px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 4px 11px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.select_box::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.select_box::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 50px;
}
.select_box::-webkit-scrollbar-track {
  background: #fafafa;
  border-width: 0px 0.5px;
  border-style: solid;
  border-color: #E8E8E8;
}
.select_box li {
  height: 40px;
  line-height: 40px;
  padding: 0 16px;
  cursor: pointer;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.select_box li:hover {
  background: #f5f5f5;
}
@media all and (max-width: 991px) {
  .select_box li {
    font-size: 14px;
  }
}
@media all and (max-width: 768px) {
  .select_box li {
    height: 35px;
    line-height: 35px;
  }
}

.modal {
  width: 90%;
  max-width: 800px;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 100;
  border-radius: 5px;
  background-color: #fff;
}

.date_input_wrap {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.react-datepicker {
  padding: 28px 24px;
  margin: 5px;
  border: none;
  border-radius: 16px;
  background: #fff;
  -webkit-box-shadow: 8px 3px 22px 10px rgba(150, 150, 150, 0.11);
          box-shadow: 8px 3px 22px 10px rgba(150, 150, 150, 0.11);
}
.react-datepicker__header {
  padding: 0;
  margin-bottom: 16px;
  border: none;
}
.react-datepicker__customHeader {
  padding-bottom: 20px;
  margin-bottom: 16px;
  border-bottom: 0.8px solid #E4E5E7;
}
.react-datepicker__customHeader .year_month {
  font-size: 14px;
  color: #323232;
}
.react-datepicker__customHeader .arrow_left,
.react-datepicker__customHeader .arrow_right {
  width: 16px;
  height: 16px;
  position: relative;
}
.react-datepicker__customHeader .arrow_left img,
.react-datepicker__customHeader .arrow_right img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.react-datepicker__customHeader .arrow_left {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.react-datepicker-wrapper {
  height: 100%;
}
.react-datepicker__input-container {
  height: 100%;
}
.react-datepicker__input-container input {
  width: 100%;
  height: 100%;
  padding: 0 16px;
  background: url(../img/icon/calendar.svg) no-repeat right 18px center;
  color: var(--color-lightGray) !important;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  width: 24px;
  height: 24px;
  line-height: 24px;
  margin: 0 16px 0 0;
  font-size: 14px;
  border-radius: 40px;
}
.react-datepicker__day-name:last-child, .react-datepicker__day:last-child, .react-datepicker__time-name:last-child {
  margin-right: 0;
}
.react-datepicker__day-name {
  font-size: 12px;
  color: #7E818C;
}
.react-datepicker__day--selected {
  font-weight: normal;
  color: #fff;
  background: #323232;
}
.react-datepicker .react-datepicker__day:not(.react-datepicker__day--in-range) {
  background-color: transparent;
  color: #000;
}
.react-datepicker .react-datepicker__day--in-range,
.react-datepicker .react-datepicker__day--selected:hover,
.react-datepicker .react-datepicker__day--in-selecting-range:hover,
.react-datepicker .react-datepicker__day--in-range:hover,
.react-datepicker .react-datepicker__month-text--selected:hover,
.react-datepicker .react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker .react-datepicker__month-text--in-range:hover,
.react-datepicker .react-datepicker__quarter-text--selected:hover,
.react-datepicker .react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker .react-datepicker__quarter-text--in-range:hover,
.react-datepicker .react-datepicker__year-text--selected:hover,
.react-datepicker .react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker .react-datepicker__year-text--in-range:hover {
  background: #323232;
  color: #fff;
  border-radius: 40px;
}
.react-datepicker .react-datepicker__month-text--selected,
.react-datepicker .react-datepicker__month-text--in-selecting-range,
.react-datepicker .react-datepicker__month-text--in-range {
  background-color: var(--color-green);
}
.react-datepicker .react-datepicker__month .react-datepicker__month-text,
.react-datepicker .react-datepicker__month .react-datepicker__quarter-text {
  width: 50px;
  padding: 5px 10px;
  margin: 5px 12px;
}
.react-datepicker .react-datepicker__day--disabled,
.react-datepicker .react-datepicker__month-text--disabled,
.react-datepicker .react-datepicker__quarter-text--disabled,
.react-datepicker .react-datepicker__year-text--disabled {
  color: #ccc !important;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end) {
  background: #f5f5f5;
  color: #323232;
}
.react-datepicker__day--outside-month:not(.react-datepicker__day--in-range, .react-datepicker__day--disabled) {
  color: #7E818C !important;
}
.react-datepicker__current-month {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 0.8px solid #E4E5E7;
}
.react-datepicker__week {
  margin-bottom: 16px;
}
.react-datepicker__week:last-child {
  margin-bottom: 0;
}
.react-datepicker__triangle {
  display: none;
}
.react-datepicker__header {
  background-color: #fff;
}
.react-datepicker__navigation {
  top: 30px;
}
.react-datepicker__navigation--next {
  right: 14px;
}
.react-datepicker__navigation--previous {
  left: 14px;
}
.react-datepicker__navigation-icon::before {
  width: 6px;
  height: 6px;
  border-width: 1.5px 1.5px 0 0;
}
.react-datepicker__children-container {
  width: 100%;
  margin: 0;
  padding: 0;
}
.react-datepicker__children-container .button-container {
  width: 100%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 28px;
}
.react-datepicker__children-container .button-container button {
  font-weight: 400;
}
.react-datepicker__children-container .button-container .btn_ctrl {
  width: 49%;
  height: 38px;
  line-height: 38px;
  border-radius: 5px;
}
@media all and (max-width: 360px) {
  .react-datepicker {
    padding: 28px 18px;
  }
}

.box_ty01 {
  background-color: #fff;
  border-radius: 5px;
}

.copy_wrap img {
  margin-left: 8px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.no_data_wrap {
  margin: 24px 0;
  width: 100%;
  text-align: center;
}

.dim {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
  background: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.write_type {
  max-width: 1541px;
  width: 100%;
  padding: 28px;
}
.write_type .input_ty02 a,
.write_type .input_ty02 input {
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  font-weight: 400;
  color: #637381;
  border-radius: 8px;
}
.write_type .input_ty02 a::-webkit-input-placeholder, .write_type .input_ty02 input::-webkit-input-placeholder {
  color: #BEC8D0;
}
.write_type .input_ty02 a::-moz-placeholder, .write_type .input_ty02 input::-moz-placeholder {
  color: #BEC8D0;
}
.write_type .input_ty02 a:-ms-input-placeholder, .write_type .input_ty02 input:-ms-input-placeholder {
  color: #BEC8D0;
}
.write_type .input_ty02 a::-ms-input-placeholder, .write_type .input_ty02 input::-ms-input-placeholder {
  color: #BEC8D0;
}
.write_type .input_ty02 a::placeholder,
.write_type .input_ty02 input::placeholder {
  color: #BEC8D0;
}
@media all and (max-width: 768px) {
  .write_type .input_ty02 a,
  .write_type .input_ty02 input {
    font-size: 14px;
  }
}
@media all and (max-width: 480px) {
  .write_type .input_ty02 a,
  .write_type .input_ty02 input {
    height: 45px;
    line-height: 45px;
    border-radius: 5px;
  }
}
.write_type .input_ty02 .btn_search {
  height: 48px;
  line-height: 48px;
  margin-left: 8px;
  border-radius: 8px;
}
@media all and (max-width: 480px) {
  .write_type .input_ty02 .btn_search {
    height: 45px;
    line-height: 45px;
    border-radius: 5px;
  }
}
.write_type .input_ty02 a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.write_type .check_type.radio label span {
  font-size: 16px;
}
@media all and (max-width: 768px) {
  .write_type .check_type.radio label span {
    font-size: 14px;
  }
}
.write_type .essential_area {
  margin-bottom: 50px;
}
@media all and (max-width: 480px) {
  .write_type .essential_area {
    margin-bottom: 25px;
  }
}
@media all and (max-width: 480px) {
  .write_type {
    padding: 15px;
  }
}

.write_tit {
  width: 84px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  padding-left: 25px;
  margin-bottom: 28px;
  font-size: 14px;
  color: #1C2434;
  background: url(../img/icon/pencil.svg) no-repeat left 6px center, #F1F4F5;
  border-radius: 5px;
}

/*
.write{
	&_type{max-width:1485px;   
		.essential_area{margin-bottom:50px;}
	}
	&_tit{@include conSize(84px, 30px, 30px); display:inline-block; padding-left:25px; margin-bottom:28px; @include font(14px, null, #1C2434); background:url(../img/icon/pencil.svg) no-repeat left 6px center, #F1F4F5; border-radius:5px;}
}
*/
.filter_wrap {
  padding: 16px 18px;
}
.filter_wrap .input_ty02 {
  max-width: 240px;
  height: 40px;
  display: inline-block;
  margin-right: 10px;
}
.filter_wrap .input_ty02.wide {
  max-width: 275px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
@media all and (max-width: 991px) {
  .filter_wrap .input_ty02.wide {
    max-width: 100%;
  }
}
.filter_wrap .input_ty02.year {
  max-width: 120px;
}
.filter_wrap .input_ty02 input,
.filter_wrap .input_ty02 .select_box {
  color: var(--color-lightGray);
}
@media all and (max-width: 1440px) {
  .filter_wrap .input_ty02, .filter_wrap .input_ty02.year {
    max-width: 100%;
    width: 100%;
    margin-bottom: 10px;
  }
}
@media all and (max-width: 480px) {
  .filter_wrap .input_ty02 {
    margin-right: 0;
  }
}
.filter_wrap .date_input_wrap .date_input {
  max-width: 200px;
}
@media all and (max-width: 1440px) {
  .filter_wrap .date_input_wrap .date_input {
    max-width: 100%;
  }
}
@media all and (max-width: 480px) {
  .filter_wrap .date_input_wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
@media all and (max-width: 480px) {
  .filter_wrap .select_input_wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.filter_wrap .btn_search {
  padding: 0 20px;
}
.filter_wrap .tab_btn {
  height: 52px;
  margin-top: -10px;
}
.filter_wrap .tab_btn li {
  width: 130px;
  height: 100%;
  display: inline-block;
  color: var(--color-lightGray);
}
.filter_wrap .tab_btn li .btn {
  width: 100%;
  height: 100%;
  display: inline-block;
}
.filter_wrap .tab_btn li .btn.active {
  font-weight: 500;
  color: #1C2434;
  border-bottom: 1px solid #1C2434;
}
@media all and (max-width: 768px) {
  .filter_wrap .tab_btn li .btn {
    font-size: 14px;
  }
}
@media all and (max-width: 768px) {
  .filter_wrap .tab_btn li {
    width: 70px;
  }
}
@media all and (max-width: 1440px) {
  .filter_wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
@media all and (max-width: 480px) {
  .filter_wrap {
    padding: 15px;
  }
}

.table {
  width: 100%;
  table-layout: fixed;
  background-color: #fff;
}
.table_type {
  min-height: 60vh;
  position: relative;
  padding-bottom: 88px;
}
.table_type .foot_btn_wrap {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 15px 18px 50px;
}
.table_type .foot_btn_wrap .btn_ty01 {
  margin-left: 13px;
}
@media all and (max-width: 768px) {
  .table_type .foot_btn_wrap .btn_ty01 {
    margin-left: 4px;
    font-size: 0;
  }
}
@media all and (max-width: 768px) {
  .table_type .foot_btn_wrap {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
@media all and (max-width: 480px) {
  .table_type {
    padding-bottom: 50px;
  }
}
.table_wrap {
  overflow-x: auto;
}
.table_wrap::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.table_wrap::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 50px;
}
.table_wrap::-webkit-scrollbar-track {
  background: #fafafa;
}
.table_wrap.line {
  border-bottom: 12px solid #fff;
}
.table_wrap.line .table {
  min-width: 1400px;
}
.table_wrap.line .table tr {
  height: 58px;
}
.table_wrap.line .table tr:nth-child(even) {
  background-color: #fff;
}
.table_wrap.line .table tr td, .table_wrap.line .table tr th { /*color:#637381;*/
  border: 1px solid #eee;
}
.table_wrap.line .table tr td:first-child, .table_wrap.line .table tr th:first-child {
  border-left: 0;
}
.table_wrap.line .table tr td:last-child, .table_wrap.line .table tr th:last-child {
  border-right: 0;
}
.table_wrap.line .table tr th {
  color: #000;
}
.table_wrap.line .table tr td {
  font-size: 14px;
}
.table tbody {
  color: var(--color-lightGray);
}
.table tbody td.etc input {
  background: transparent;
}
.table tr {
  height: 68px;
  text-align: center;
}
.table tr th {
  background-color: #FBFCFC;
}
.table tr .info_link {
  height: 68px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.table tr:nth-child(even) {
  background: #FBFCFC;
}
.table th, .table td {
  line-height: 1;
  padding: 8px 18px;
  word-wrap: break-word;
}
.table th.overflow, .table td.overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table .input_ty02 input {
  text-align: center;
}
.table .check_type:not(.radio) span::before {
  margin-right: 0;
}
@media all and (max-width: 991px) {
  .table {
    font-size: 14px;
  }
}

.table_comm.table_type .table_wrap .table tr {
  height: 68px;
  line-height: 68px;
}
.table_comm.table_type .table_wrap .table tr .check {
  width: 50px;
}
.table_comm.table_type .table_wrap .table tr .num {
  width: 100px;
}
.table_comm.table_type .table_wrap .table tr .id, .table_comm.table_type .table_wrap .table tr .email {
  width: 180px;
}
.table_comm.table_type .table_wrap .table tr .name, .table_comm.table_type .table_wrap .table tr .gender, .table_comm.table_type .table_wrap .table tr .birth, .table_comm.table_type .table_wrap .table tr .people {
  width: 120px;
}
.table_comm.table_type .table_wrap .table tr .phone, .table_comm.table_type .table_wrap .table tr .joinDate, .table_comm.table_type .table_wrap .table tr .active {
  width: 150px;
}
.table_comm.table_type .table_wrap .table tr .etc {
  width: 200px;
}
@media all and (max-width: 480px) {
  .table_comm.table_type .table_wrap .table tr {
    height: 63px;
    line-height: 63px;
  }
}
.table_comm.table_type .table_wrap .table thead {
  border-top: 1px solid #EBEFF2;
  border-bottom: 1px solid #EBEFF2;
  background: #FBFCFC;
}
.table_comm.table_type .table_wrap .table thead th {
  line-height: 1.1;
  font-size: 16px;
  font-weight: 400;
  color: #000;
}
@media all and (max-width: 480px) {
  .table_comm.table_type .table_wrap .table thead th {
    font-size: 15px;
  }
}
.table_comm.table_type .table_wrap .table tbody td {
  line-height: 1.2;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-lightGray);
}
.table_comm.table_type .table_wrap .table tbody td a {
  font-size: inherit;
}
.table_comm.table_type .table_wrap .table tbody td.input_ty02 input, .table_comm.table_type .table_wrap .table tbody td .input_ty02 input {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
.table_comm.table_type .table_wrap .table tbody td.input_ty02 input::-webkit-input-placeholder, .table_comm.table_type .table_wrap .table tbody td .input_ty02 input::-webkit-input-placeholder {
  color: #637381;
}
.table_comm.table_type .table_wrap .table tbody td.input_ty02 input::-moz-placeholder, .table_comm.table_type .table_wrap .table tbody td .input_ty02 input::-moz-placeholder {
  color: #637381;
}
.table_comm.table_type .table_wrap .table tbody td.input_ty02 input:-ms-input-placeholder, .table_comm.table_type .table_wrap .table tbody td .input_ty02 input:-ms-input-placeholder {
  color: #637381;
}
.table_comm.table_type .table_wrap .table tbody td.input_ty02 input::-ms-input-placeholder, .table_comm.table_type .table_wrap .table tbody td .input_ty02 input::-ms-input-placeholder {
  color: #637381;
}
.table_comm.table_type .table_wrap .table tbody td.input_ty02 input::placeholder, .table_comm.table_type .table_wrap .table tbody td .input_ty02 input::placeholder {
  color: #637381;
}
@media all and (max-width: 480px) {
  .table_comm.table_type .table_wrap .table tbody td.input_ty02 input, .table_comm.table_type .table_wrap .table tbody td .input_ty02 input {
    font-size: 13px;
  }
}
.table_comm.table_type .table_wrap .table tbody td.id.disabled {
  height: 68px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0;
  margin: 0 auto;
}
.table_comm.table_type .table_wrap .table tbody td.id.disabled::after {
  content: "";
  width: 18px;
  height: 18px;
  display: block;
  position: static;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  margin-left: 8px;
  background: url(../img/icon/stop.svg) no-repeat center;
}
@media all and (max-width: 480px) {
  .table_comm.table_type .table_wrap .table tbody td {
    font-size: 13px;
  }
}
.table_comm.table_type .table_wrap.line {
  border-bottom: 12px solid #fff;
}
.table_comm.table_type .table_wrap.line .table {
  min-width: 1400px;
}
.table_comm.table_type .table_wrap.line .table tr {
  height: 68px;
}
.table_comm.table_type .table_wrap.line .table tr:nth-child(even) {
  background-color: #fff;
}
.table_comm.table_type .table_wrap.line .table tr td, .table_comm.table_type .table_wrap.line .table tr th {
  border: 1px solid #eee;
}
.table_comm.table_type .table_wrap.line .table tr td:first-child, .table_comm.table_type .table_wrap.line .table tr th:first-child {
  border-left: 0;
}
.table_comm.table_type .table_wrap.line .table tr td:last-child, .table_comm.table_type .table_wrap.line .table tr th:last-child {
  border-right: 0;
}
.table_comm.table_type .table_wrap.part tbody td.input_ty02 input, .table_comm.table_type .table_wrap.part tbody td .input_ty02 input {
  height: 52px;
  line-height: 52px;
}
@media all and (max-width: 480px) {
  .table_comm.table_type .table_wrap.part tbody td.input_ty02 input, .table_comm.table_type .table_wrap.part tbody td .input_ty02 input {
    height: 47px;
    line-height: 47px;
  }
}
.table_comm.table_type .table_wrap.part tbody td.input_ty02::before, .table_comm.table_type .table_wrap.part tbody td .input_ty02::before {
  top: 16px;
}

.detail_form {
  padding-top: 30px;
}
.detail_form .radio_box:first-child {
  margin-right: 5px;
}
.detail_form .radio_box .check_type.radio {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.detail_form .radio_box .check_type.radio span {
  padding-left: 25px;
  font-size: 14px;
}
.detail_form .radio_box .check_type.radio span::before {
  width: 18px;
  height: 18px;
}
.detail_form .radio_box .check_type.radio span::after {
  width: 10px;
  height: 10px;
  left: 4px;
}
.detail_form .table_wrap {
  margin: 0 20px;
  border-bottom: 0;
}
.detail_form .table_wrap .table tr td:first-child, .detail_form .table_wrap .table tr td:last-child, .detail_form .table_wrap .table tr th:first-child, .detail_form .table_wrap .table tr th:last-child {
  border: 1px solid #eee;
}
.detail_form .table_wrap .table tr th {
  line-height: 1.1;
  font-size: 16px;
  font-weight: 400;
  color: #000;
}
.detail_form .table_wrap .table tr td {
  line-height: 1.2;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-lightGray);
}

.view_form .flex_box label,
.view_form .flex_box .label {
  max-width: 220px;
}
.view_form .flex_left,
.view_form .flex_right {
  max-width: 100%;
}
.view_form .w100 {
  max-width: 100%;
}
.view_form .w100.flex_left {
  margin-right: 0;
}
.view_form .textarea {
  min-height: 200px;
}
.view_form .img_area {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 30px;
}
.view_form .img_area img {
  width: 40%;
}
@media all and (max-width: 991px) {
  .view_form .img_area img {
    width: 100%;
  }
}
@media all and (max-width: 1200px) {
  .view_form .img_area {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    margin-bottom: 15px;
  }
}
.view_form .btn_plus {
  width: 100%;
  padding: 8px 16px;
  border: 1px solid #E0E2E7;
  border-radius: 5px;
  text-align: left;
  color: var(--color-lightGray);
}
.view_form .btn_plus span {
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
  color: var(--color-lightGray);
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
@media all and (max-width: 991px) {
  .view_form .btn_plus {
    font-size: 14px;
  }
}
.view_form .btn_close {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-60%);
          transform: translateY(-60%);
  color: var(--color-lightGray);
}
.view_form .file_box {
  width: 100%;
}
.view_form .file_box .row {
  position: relative;
  margin-bottom: 10px;
}
.view_form .file_box .row input {
  padding-right: 30px;
  color: var(--color-lightGray);
}
.view_form.add .file_box {
  width: calc(100% - 220px);
  margin: -20px 0 30px auto;
}
@media all and (max-width: 1200px) {
  .view_form.add .file_box {
    width: 100%;
    margin: 0 0 15px;
  }
}

.flex_box {
  min-height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
}
.flex_box input,
.flex_box textarea {
  color: var(--color-lightGray);
}
.flex_box textarea {
  min-height: 124px;
  resize: none;
}
.flex_box .addr_wrap,
.flex_box .id_wrap {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.flex_box .addr_wrap input,
.flex_box .id_wrap input {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.flex_box:last-child {
  margin-bottom: 0;
}
@media all and (max-width: 1200px) {
  .flex_box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 0;
  }
}
.flex_box label,
.flex_box .label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 250px;
  width: 100%;
}
@media all and (max-width: 1440px) {
  .flex_box label,
  .flex_box .label {
    max-width: 180px;
  }
}
@media all and (max-width: 1200px) {
  .flex_box label,
  .flex_box .label {
    max-width: 150px;
    margin: 0 10px 10px 0;
    font-size: 14px;
  }
}
.flex_box .select_box {
  color: var(--color-lightGray);
}
.flex_box .file_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.flex_box .file_wrap > * {
  width: 126px;
  height: 126px;
  display: inline-block;
  border-radius: 8px;
}
.flex_box .btn_file_add {
  margin-right: 14px;
  color: var(--color-green);
  border: 0.8px dashed var(--color-green);
}
.flex_box .btn_file_add label {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  cursor: pointer;
}
.flex_box .btn_file_add label .plus {
  display: inline-block;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  font-size: 22px;
  font-weight: 600;
}
.flex_box .btn_file_add label p {
  display: block;
  font-size: 12px;
}
.flex_box .btn_file_add label input[type=file] {
  position: absolute;
  visibility: hidden;
}
.flex_box .profile_img {
  position: relative;
  overflow: hidden;
  font-size: 12px;
  color: #fff;
  text-align: center;
}
.flex_box .profile_img:hover .hover {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.flex_box .profile_img .hover {
  width: 100%;
  height: 100%;
  display: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  padding: 5px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5)));
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  -webkit-backdrop-filter: blur(2.5px);
          backdrop-filter: blur(2.5px);
}
.flex_box .profile_img .file_name {
  word-break: break-all;
}
.flex_box .profile_img .btn_del {
  margin-top: 10px;
  text-decoration: underline;
}
.flex_box .row {
  width: 100%;
}
.flex_box .row .flex_right {
  margin-bottom: 30px;
}
.flex_box .row .flex_right:last-child {
  margin-bottom: 0;
}
@media all and (max-width: 1200px) {
  .flex_box .row .flex_right:last-child {
    margin-bottom: 15px;
  }
}
@media all and (max-width: 1200px) {
  .flex_box .row .flex_right {
    margin-bottom: 15px;
  }
}
.flex_left {
  width: 50%;
  margin-right: 5%;
}
.flex_left, .flex_right {
  max-width: 650px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media all and (max-width: 1200px) {
  .flex_left, .flex_right {
    max-width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    margin: 0 0 15px 0;
  }
}

.flex_box.flex_box_mb {
  margin-bottom: 0;
}

.flex_left.flex_box_mr {
  margin-right: 0;
}

.faq_add .write_type {
  max-width: 1541px;
}
.faq_add .flex_box .flex_left {
  margin-right: 13%;
}
.faq_add .flex_box .flex_left.flex_box_mr {
  margin-right: 0;
}
.faq_add .flex_box label {
  max-width: 248px;
}
.faq_add .input_ty02 input {
  height: 48px;
  line-height: 48px;
  border-radius: 8px;
}
.faq_add .input_ty02::before {
  top: 15px;
}
.faq_add .check_type.radio label span {
  padding-left: 45px;
}

.tip_add .input_ty02 input {
  height: 48px;
  line-height: 48px;
  border-radius: 8px;
}

.find_file_wrap {
  width: 100%;
  height: 55px;
  line-height: 55px;
  position: relative;
  padding: 0 17px;
  border: 1px solid #E0E2E7;
  border-radius: 8px;
}
.find_file_wrap .file_label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 1173px !important;
  -webkit-column-gap: 4px;
     -moz-column-gap: 4px;
          column-gap: 4px;
  color: #637381;
}
.find_file_wrap .file_label span {
  width: 19px;
  height: 19px;
  display: block;
  background: url(../img/icon-plus.png) center no-repeat;
  background-size: cover;
}
.find_file_wrap .file_label p {
  padding-left: 20px;
  max-width: 28%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.find_file_wrap .file {
  width: 120px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.find_file.input_ty02 input {
  height: 55px;
  line-height: 55px;
}

.view_form .flex_box .label {
  max-width: 248px;
}
.view_form.add .file_box {
  width: calc(100% - 248px);
}
.view_form .textarea {
  border-radius: 8px;
}
.view_form .textarea.faq_content {
  min-height: 349px;
  padding: 20px 16px;
}
.view_form .textarea.faq_note {
  min-height: 88px;
  padding: 12px 16px;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  word-break: keep-all;
  list-style: none;
  border-collapse: collapse;
  text-decoration: none;
  font-family: "NotoSansKR";
}

a {
  color: inherit;
  text-decoration: none;
}
a:hover, a:focus, a:active {
  background: none;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6, th {
  font-weight: 400;
}

ul, ol {
  list-style: none;
}

i, em, address {
  font-style: normal;
}

img, fieldset {
  max-width: 100%;
  vertical-align: top;
  outline: none;
}

img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

iframe {
  border: none;
}

button {
  display: inline-block;
  outline: none;
  font-size: inherit;
  color: inherit;
  background: none;
  border: none;
  cursor: pointer;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input, textarea {
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}

.clearfix::before,
.clearfix::after {
  content: "";
  display: block;
  clear: both;
  overflow: hidden;
}

.side_menu {
  width: 76px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 25px 0;
  overflow-y: auto;
  overflow-x: hidden;
  background: #1C2434;
  color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.side_menu::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.side_menu::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: #555;
}
.side_menu::-webkit-scrollbar-track {
  background: #1C2434;
}
@media all and (max-width: 991px) {
  .side_menu {
    left: -100%;
  }
  .side_menu.wide {
    left: 0;
  }
}
.side_menu .logo {
  width: 40px;
  display: inline-block;
  margin-bottom: 40px;
}
.side_menu_list .btn_wrap .list_btn {
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  padding: 10px;
  margin-bottom: 22px;
  white-space: nowrap;
  font-size: 0;
  font-weight: 500;
  color: #DEE4EE;
  border-radius: 5px;
  background: url(../img/icon/menu_user.svg) no-repeat left 10px center;
  text-align: left;
}
.side_menu_list .btn_wrap .list_btn::after {
  content: "";
  width: 11px;
  height: 8px;
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  background: url(../img/icon/angle_down_w.svg) no-repeat center;
}
.side_menu_list .btn_wrap .list_btn.active {
  background-color: #060F20;
}
.side_menu_list .btn_wrap .list_btn.active::after {
  -webkit-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
}
.side_menu_list .btn_wrap .list_btn:hover {
  background-color: #060F20;
}
@media all and (max-width: 480px) {
  .side_menu_list .btn_wrap .list_btn {
    margin-bottom: 17px;
  }
}
.side_menu_list .btn_wrap .sub_menu_list {
  display: none;
  padding-left: 40px;
  margin: 0 0 22px;
  white-space: nowrap;
  color: #8A99AF;
}
.side_menu_list .btn_wrap .sub_menu_list .list {
  margin-bottom: 10px;
  font-weight: 500;
}
.side_menu_list .btn_wrap .sub_menu_list .list a {
  width: 100%;
  display: inline-block;
  font-size: 16px;
}
@media all and (max-width: 480px) {
  .side_menu_list .btn_wrap .sub_menu_list .list a {
    font-size: 14px;
  }
}
.side_menu_list .btn_wrap .sub_menu_list .list:last-child {
  margin-bottom: 0;
}
.side_menu_list .btn_wrap .sub_menu_list .list.active {
  color: var(--color-green);
}
.side_menu_list .btn_wrap.event .list_btn {
  background-image: url(../img/icon/menu_event.svg);
}
.side_menu_list .btn_wrap.calc .list_btn {
  background-image: url(../img/icon/menu_calc.svg);
}
.side_menu_list .btn_wrap.calc .list_btn::after {
  display: none !important;
}
.side_menu_list .btn_wrap.point .list_btn {
  background-image: url(../img/icon/menu_point.svg);
}
.side_menu_list .btn_wrap.point .list_btn::after {
  display: none !important;
}
.side_menu_list .btn_wrap.board .list_btn {
  background-image: url(../img/icon/menu_board.svg);
}
.side_menu_list .btn_wrap.device .list_btn {
  background-image: url(../img/icon/menu_device.svg);
}
.side_menu.wide {
  width: 286px;
}
.side_menu.wide .btn_wrap .list_btn {
  width: 100%;
  padding-right: 30px;
  padding-left: 38px;
  font-size: 16px;
}
.side_menu.wide .btn_wrap .list_btn::after {
  opacity: 1;
  visibility: visible;
}
.side_menu.wide .btn_wrap .list_btn::after {
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}
.side_menu.wide .btn_wrap .list_btn.active + .sub_menu_list {
  display: block;
}
@media all and (max-width: 480px) {
  .side_menu.wide .btn_wrap .list_btn {
    font-size: 14px;
  }
}

.container {
  width: calc(100% - 76px);
  height: 100%;
  min-height: 100vh;
  padding: 154px 45px 0 45px;
  margin-left: auto;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.container.home {
  padding-top: 106px;
}
.container_item {
  width: calc(100% - 76px);
}
@media all and (max-width: 991px) {
  .container {
    width: 100%;
    padding: 134px 20px 0 20px;
  }
}

.container_item {
  position: fixed;
  top: 0;
  left: 76px;
  z-index: 5;
  padding: 0 45px;
  background-color: #fff;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
@media all and (max-width: 991px) {
  .container_item {
    width: 100%;
    left: 0;
    padding: 0 20px;
  }
}
@media all and (max-width: 480px) {
  .container_item {
    padding: 0 15px;
  }
}

.content_container {
  padding-bottom: 60px;
}

.header {
  height: 80px;
  z-index: 6;
  -webkit-box-shadow: 0px 1px 0px #E2E8F0;
          box-shadow: 0px 1px 0px #E2E8F0;
}
.header .ham_btn {
  width: 24px;
  height: 24px;
  display: none;
  position: relative;
}
.header .ham_btn span {
  width: 20px;
  position: absolute;
  top: 4px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-top: 2px solid #1C2434;
  border-radius: 50px;
}
.header .ham_btn span::before {
  width: 100%;
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  border-top: 2px solid #1C2434;
}
.header .ham_btn span::after {
  content: "";
  width: 100%;
  position: absolute;
  top: 12px;
  left: 0;
  border-top: 2px solid #1C2434;
}
@media all and (max-width: 991px) {
  .header .ham_btn {
    display: block;
  }
}
.header .search_wrap {
  width: 100%;
  max-width: 816px; /*max-width:532px;*/
  margin-right: 50px;
}
@media all and (max-width: 991px) {
  .header .search_wrap {
    max-width: 100%;
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    padding: 10px 20px;
    background-color: #fff;
    border-top: 1px solid #E2E8F0;
  }
  .header .search_wrap.show {
    display: block;
  }
}
.header .search_wrap input {
  width: 100%;
  height: 40px;
  padding-right: 10px;
  padding-left: 42px;
  background: url(../img/icon/search.svg) no-repeat left 20px center;
  border: 1px solid #E2E8F0;
  border-radius: 50px;
}
.header .search_wrap input::-webkit-input-placeholder {
  color: var(--color-lightGray);
}
.header .search_wrap input::-moz-placeholder {
  color: var(--color-lightGray);
}
.header .search_wrap input:-ms-input-placeholder {
  color: var(--color-lightGray);
}
.header .search_wrap input::-ms-input-placeholder {
  color: var(--color-lightGray);
}
.header .search_wrap input::placeholder {
  color: var(--color-lightGray);
}
@media all and (max-width: 991px) {
  .header .search_wrap input {
    padding: 0 20px;
    background: none;
  }
}
.header .menu_wrap {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
.header .menu_wrap > * {
  margin-left: 30px;
}
@media all and (max-width: 991px) {
  .header .menu_wrap > * {
    margin-left: 20px;
  }
}
.header .menu_wrap .menu_btn {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
}
.header .menu_wrap .menu_btn_search {
  display: none;
  background-image: url(../img/icon/search_black.svg);
  background-size: 24px;
}
@media all and (max-width: 991px) {
  .header .menu_wrap .menu_btn_search {
    display: block;
  }
}
.header .menu_wrap .menu_btn_logout {
  background-image: url(../img/icon/logout.svg);
}
.header .menu_wrap .menu_btn_alarm {
  background-image: url(../img/icon/alarm.svg);
}
.header .menu_wrap .menu_btn_alarm.active {
  position: relative;
}
.header .menu_wrap .menu_btn_alarm.active::before {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  top: 0;
  left: 1px;
  background: #FF5789;
  border: 1px solid #fff;
  border-radius: 50%;
}
.header .menu_wrap .info_text {
  font-size: 14px;
  font-weight: 500;
  text-align: right;
}
.header .menu_wrap .info_text span {
  font-size: 12px;
  color: var(--color-lightGray);
}
@media all and (max-width: 480px) {
  .header .menu_wrap .info_text {
    font-size: 13px;
  }
}
@media all and (max-width: 991px) {
  .header {
    height: 60px;
  }
}

.lnb {
  height: 60px;
  line-height: 60px;
  top: 80px;
  padding-right: 0;
  padding-left: 25px;
  border-top: 1px solid #EBEFF2;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);
          box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);
}
@media all and (max-width: 991px) {
  .lnb {
    top: 60px;
    padding-left: 0;
  }
}
.lnb_tab_slide .swiper-slide {
  width: auto;
  height: auto;
}
.lnb_tab_slide .swiper-slide.active .lnb_btn {
  position: relative;
  font-weight: 500;
  color: #1C2434;
}
.lnb_tab_slide .swiper-slide.active .lnb_btn::after {
  content: "";
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #1C2434;
}
@media all and (max-width: 991px) {
  .lnb_tab_slide .swiper-slide.active .lnb_btn::after {
    bottom: 2px;
  }
}
.lnb_tab_slide .lnb_btn {
  width: 100%;
  height: 100%;
  display: inline-block;
  padding: 0 28px;
  font-weight: 400;
  color: var(--color-lightGray);
}
@media all and (max-width: 991px) {
  .lnb_tab_slide .lnb_btn {
    padding: 0 15px;
    font-size: 14px;
  }
}
@media all and (max-width: 480px) {
  .lnb {
    height: 50px;
    line-height: 50px;
  }
}

.current_box {
  margin-bottom: 14px;
  padding: 14px 18px;
}
.current_box .current_tit {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  position: relative;
  padding-left: 21px;
  font-size: 18px;
  font-weight: 500;
  color: #1C2434;
}
.current_box .current_tit::before {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: #1C2434;
  border-radius: 50%;
}
@media all and (max-width: 768px) {
  .current_box .current_tit::before {
    width: 5px;
    height: 5px;
  }
}
@media all and (max-width: 991px) {
  .current_box .current_tit {
    font-size: 16px;
  }
}
@media all and (max-width: 768px) {
  .current_box .current_tit {
    font-size: 14px;
  }
}
@media all and (max-width: 480px) {
  .current_box .current_tit {
    padding-left: 16px;
  }
}
.current_box .setting_btn_wrap {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
@media all and (max-width: 768px) {
  .current_box .setting_btn_wrap .btn_ty01 {
    margin-top: 15px;
    background-position: center;
  }
}
@media (max-width: 1085px) {
  .current_box .setting_btn_wrap {
    -webkit-box-flex: 2;
        -ms-flex: 2;
            flex: 2;
  }
}
@media all and (max-width: 768px) {
  .current_box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .current_box .setting_btn_wrap .btn_ty01 {
    margin-top: 15px;
    background-position: center;
  }
}
@media all and (max-width: 480px) {
  .current_box {
    padding: 15px;
  }
}

#pagination {
  width: 100%;
  position: absolute;
  bottom: 44px;
}
#pagination .btn {
  width: 42px;
  height: 44px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  display: block;
  position: relative;
  padding: 0;
  margin-left: -1px;
  background: #fff;
  border: 1px solid #E0E2E7;
  font-weight: 500;
  color: #232325;
  text-align: center;
}
#pagination .btn.active {
  color: var(--color-green);
}
#pagination .btn:hover {
  background-color: #f9f9f9;
}
#pagination .btn img {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
}
#pagination .btn.arrow_left {
  border-radius: 4px 0px 0px 4px;
}
#pagination .btn.arrow_left img {
  -webkit-transform: translate(-50%, -50%) rotate(90deg);
          transform: translate(-50%, -50%) rotate(90deg);
}
#pagination .btn.arrow_right {
  border-radius: 0px 4px 4px 0px;
}
#pagination .btn.arrow_right img {
  -webkit-transform: translate(-50%, -50%) rotate(-90deg);
          transform: translate(-50%, -50%) rotate(-90deg);
}
@media all and (max-width: 991px) {
  #pagination .btn {
    font-size: 14px;
  }
}
@media all and (max-width: 768px) {
  #pagination .btn {
    width: 32px;
    height: 34px;
  }
}

.search_addr_background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  visibility: hidden;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.search_addr_background.active {
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.5);
}
.search_addr_background.active .search_addr_wrap {
  bottom: 0;
}
.search_addr_background .search_addr_wrap {
  width: 100vw;
  height: 80vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: absolute;
  bottom: -100%;
  left: 0;
  z-index: 10;
  padding: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #fff;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.search_addr_background .search_addr_wrap header {
  margin-bottom: 24px;
}
.search_addr_background .search_addr_wrap header h3 {
  margin: -2px 0 20px;
  font-size: 14px;
  text-align: center;
}
.search_addr_background .search_addr_wrap header form {
  width: 100%;
}
.search_addr_background .search_addr_wrap header form input {
  width: 100%;
  padding: 8px;
  border-radius: 10px;
  background-color: rgba(238, 238, 238, 0.9333333333);
}
.search_addr_background .search_addr_wrap header .btn_close {
  position: absolute;
  top: 12px;
  right: 24px;
  font-size: 22px;
  font-weight: 300;
}
.search_addr_background .search_addr_wrap .search_addr_guide dt {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: bold;
}
.search_addr_background .search_addr_wrap .search_addr_guide dd {
  margin-bottom: 8px;
  font-size: 14px;
}
.search_addr_background .search_addr_wrap .search_addr_guide dd span {
  color: #777;
}
.search_addr_background .search_addr_wrap .search_addr_list {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.search_addr_background .search_addr_wrap .search_addr_list::-webkit-scrollbar {
  display: none;
}
.search_addr_background .search_addr_wrap .search_addr_list dt {
  margin: 24px 0 16px 0;
  font-size: 14px;
}
.search_addr_background .search_addr_wrap .search_addr_list dd {
  padding: 8px 0;
  border-bottom: 1px solid #ccc;
}
.search_addr_background .search_addr_wrap .search_addr_list dd p {
  font-size: 14px;
}
.search_addr_background .search_addr_wrap .search_addr_list dd span {
  font-size: 14px;
  color: #777;
}

.login {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: #fff;
}
.login .wrap {
  max-width: 500px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 0 20px;
}
.login_top {
  margin-bottom: 40px;
  text-align: center;
}
.login_top .logo_img {
  width: 200px;
  margin: 0 auto 10px;
}
.login_tit {
  font-size: 18px;
}
.login_input .input_wrap {
  margin: 20px 0;
}
.login_input .btn_ty01 {
  max-width: 100%;
  margin-top: 40px;
}

.user_info.noActive .user_id label {
  position: relative;
}
.user_info.noActive .user_id label::after {
  width: 18px;
  height: 18px;
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin-left: 5px;
  background: url(../img/icon/stop.svg) no-repeat center;
}

.user_history_pro .table tr td.promotion a, .user_history_pro .table tr th.promotion a {
  width: 100%;
  height: 100%;
  display: inline-block;
}
.user_history_pro .table tr td.promotion .arrow_right, .user_history_pro .table tr th.promotion .arrow_right {
  width: 18px;
  margin-left: 8px;
  vertical-align: sub;
}

.user_history_rank .table_wrap.line thead tr {
  height: 40px;
}

@media all and (max-width: 768px) {
  .user_history_stemp .date_input_wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.user_history_stemp .stemp_num {
  text-align: center;
}

@media all and (max-width: 768px) {
  .user_history_qna .filter_wrap .select_input_wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.user_history_qna_detail .table textarea {
  min-height: 120px;
  max-height: 200px;
  resize: none;
}

.ip_box {
  width: 100%;
  position: relative;
}
.ip_box .error_text {
  position: absolute;
  bottom: -28px;
  color: red;
}
@media all and (max-width: 1440px) {
  .ip_box .error_text {
    font-size: 14px;
  }
}

@media all and (max-width: 1440px) {
  .user_add .flex_box {
    margin-bottom: 20px;
  }
}
.user_add .flex_left {
  margin-right: 12.3%;
}
@media all and (max-width: 1440px) {
  .user_add .flex_left {
    margin-right: 7%;
  }
}
.user_add .input_ty02 label, .user_add .input_ty02 .label {
  max-width: 248px;
}
@media all and (max-width: 1440px) {
  .user_add .input_ty02 label, .user_add .input_ty02 .label {
    max-width: 200px;
  }
}
.user_add .input_ty02::before {
  top: 14px;
  right: 16px;
}
.user_add .flex_box .file-plus {
  width: 32px;
  height: 32px;
  margin: 0 auto;
  margin-bottom: 3px;
  background: url(../img/icon-plus-g.png) center no-repeat;
}
.user_add .check_type.radio {
  margin-right: 15%;
  margin-bottom: 0;
}
.user_add .check_type.radio label span {
  padding-left: 43px;
  color: #6F727A;
}
.user_add .account .check_type.radio {
  margin-right: 5.3%;
}
.user_add .account .check_type.radio label span {
  color: #6F727A;
}
.user_add .gender > label {
  max-width: 248px;
}
@media all and (max-width: 1440px) {
  .user_add .gender > label {
    max-width: 200px;
  }
}
.user_add .gender .check_type.radio {
  margin-right: 20.5%;
}
.user_add .hands > label {
  max-width: 248px;
}
@media all and (max-width: 1440px) {
  .user_add .hands > label {
    max-width: 200px;
  }
}
.user_add .hands .check_type.radio {
  margin-right: 17%;
}
.user_add .marry > label {
  max-width: 248px;
}
@media all and (max-width: 1440px) {
  .user_add .marry > label {
    max-width: 200px;
  }
}
.user_add .account > .label {
  max-width: 248px;
}
@media all and (max-width: 1440px) {
  .user_add .account > .label {
    max-width: 200px;
  }
}

.user_history_point .filter_wrap {
  -ms-flex-flow: wrap;
      flex-flow: wrap;
}
.user_history_point .filter_wrap .user_point_total {
  width: 100%;
  margin-bottom: 10px;
}
.user_history_point .filter_wrap .user_point_total span {
  font-weight: 500;
  color: var(--color-darkGreen);
}
@media all and (max-width: 480px) {
  .user_history_point .filter_wrap .user_point_total {
    font-size: 14px;
  }
}
@media all and (max-width: 860px) {
  .user_history_point .filter_wrap .select_input_wrap .select_input {
    margin-right: 0;
  }
}
@media all and (max-width: 860px) {
  .user_history_point .filter_wrap .select_input_wrap .date_input_wrap {
    width: calc(100% - 66px);
  }
}
@media all and (max-width: 860px) {
  .user_history_point .filter_wrap .select_input_wrap {
    width: 100%;
    margin-right: 0;
  }
}
.user_history_point .table_wrap .write_row .current_box {
  margin: 0;
  padding: 0;
}
.user_history_point .table_wrap .write_row .current_box .current_tit {
  display: none;
}
.user_history_point .table_wrap .write_row .current_box .setting_btn_wrap button {
  width: 100%;
  margin: 0;
  padding: 0;
  background-image: initial;
}
@media all and (max-width: 768px) {
  .user_history_point .table_wrap .write_row .current_box .setting_btn_wrap button {
    font-size: inherit;
  }
}
@media all and (max-width: 768px) {
  .user_history_point .table_wrap .write_row .current_box .setting_btn_wrap {
    width: 100%;
  }
}
@media all and (max-width: 480px) {
  .user_history_point .table_wrap .write_row .current_box .setting_btn_wrap {
    margin: 0 auto;
  }
}

.user_history_stemp .table_wrap .write_row .current_box {
  margin: 0;
  padding: 0;
}
.user_history_stemp .table_wrap .write_row .current_box .current_tit {
  display: none;
}
.user_history_stemp .table_wrap .write_row .current_box .setting_btn_wrap button {
  width: 100%;
  margin: 0;
  padding: 0;
  background-image: initial;
}
@media all and (max-width: 768px) {
  .user_history_stemp .table_wrap .write_row .current_box .setting_btn_wrap button {
    font-size: inherit;
  }
}
@media all and (max-width: 768px) {
  .user_history_stemp .table_wrap .write_row .current_box .setting_btn_wrap {
    width: 100%;
  }
}
@media all and (max-width: 480px) {
  .user_history_stemp .table_wrap .write_row .current_box .setting_btn_wrap {
    margin: 0 auto;
  }
}

.dashboard .box_ty01 {
  width: 100%;
  padding: 23px 18px 0 18px;
  margin-right: 26px;
  margin-bottom: 26px;
}
.dashboard .box_ty01:last-of-type {
  margin-right: 0;
}
.dashboard .box_ty01 .text_wrap {
  margin: 1px 0 0 0;
}
@media all and (max-width: 991px) {
  .dashboard .box_ty01 {
    margin-right: 20px;
    margin-bottom: 20px;
  }
}
@media all and (max-width: 860px) {
  .dashboard .box_ty01 {
    padding: 24px 18px;
  }
}
.dashboard .circle_icon {
  width: 56px;
  height: 56px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  display: inline-block;
  margin-right: 18px;
  background: url(../img/icon/graph.svg) no-repeat center/34px, #F1F4F5;
  border-radius: 50%;
}
@media all and (max-width: 991px) {
  .dashboard .circle_icon {
    width: 46px;
    height: 46px;
    background-size: 25px;
  }
}
.dashboard .circle_icon.dollar {
  background-image: url(../img/icon/dollar.svg);
}
.dashboard .circle_icon.people {
  background-image: url(../img/icon/people.svg);
}
.dashboard .circle_icon.document {
  background-image: url(../img/icon/document.svg);
}
.dashboard .circle_icon.card {
  background-image: url(../img/icon/card.svg);
}
.dashboard .tit {
  margin-bottom: 24px;
  font-weight: 500;
  color: #1C2434;
}
.dashboard strong {
  color: #23272E;
}
.dashboard .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.dashboard .dot_menu {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url(../img/icon/dots.svg) no-repeat center;
}
.dashboard .dot_menu:active {
  outline: none;
}
.dashboard .c_gray {
  color: #8B909A;
}
.dashboard .row1 .box_ty01 {
  width: calc(20% - 21px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 20px 18px;
  border-radius: 5px;
}
@media all and (max-width: 1440px) {
  .dashboard .row1 .box_ty01 {
    width: calc(33.3333333333% - 15px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .dashboard .row1 .box_ty01:nth-child(1), .dashboard .row1 .box_ty01:nth-child(2) {
    width: calc(50% - 13px);
  }
  .dashboard .row1 .box_ty01:nth-child(2) {
    margin-right: 0;
  }
}
@media all and (max-width: 860px) {
  .dashboard .row1 .box_ty01 {
    padding: 24px 18px;
  }
}
@media all and (max-width: 768px) {
  .dashboard .row1 .box_ty01 {
    width: 100% !important;
    margin-right: 0 !important;
  }
}
.dashboard .row1 .box_ty01:last-child {
  margin-right: 0;
}
.dashboard .row1 .tit {
  font-size: 14px;
  color: var(--color-lightGray);
  margin-bottom: 5px;
}
@media all and (max-width: 480px) {
  .dashboard .row1 .tit {
    margin-bottom: 0;
  }
}
.dashboard .row1 strong {
  line-height: 1;
  font-size: 24px;
  letter-spacing: -0.5px;
}
@media all and (max-width: 991px) {
  .dashboard .row1 strong {
    font-size: 18px;
  }
}
.dashboard .row2 .box_ty01 {
  width: calc(25% - 18px);
  border-radius: 5px;
}
@media all and (max-width: 1440px) {
  .dashboard .row2 .box_ty01.graph_ty01, .dashboard .row2 .box_ty01.graph_ty02 {
    width: calc(50% - 13px);
  }
}
@media all and (max-width: 768px) {
  .dashboard .row2 .box_ty01.graph_ty01, .dashboard .row2 .box_ty01.graph_ty02 {
    width: 100%;
    margin-right: 0;
  }
}
.dashboard .row2 .box_ty01.graph_ty02 .label_value {
  display: inline-block;
  margin-left: 15px;
  margin-top: 3px;
  font-weight: 500;
  color: #232D42;
}
.dashboard .row2 .box_ty01.graph_ty02 .tit {
  margin-bottom: 0;
}
@media all and (max-width: 1440px) {
  .dashboard .row2 .box_ty01.graph_ty02 {
    margin-right: 0;
  }
}
.dashboard .row2 .box_ty01.graph_ty03 {
  width: calc(50% - 16px);
  position: relative;
}
.dashboard .row2 .box_ty01.graph_ty03 .box_head {
  width: calc(100% - 36px);
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: absolute;
  top: 24px;
  left: 50%;
  z-index: 55;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
@media all and (max-width: 1440px) {
  .dashboard .row2 .box_ty01.graph_ty03 {
    width: 100%;
  }
}
.dashboard .row2 .graph_ty01 .tit {
  margin-bottom: 37px;
}
.dashboard .row2 .graph_ty01 .box_body_left {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
.dashboard .row2 .graph_ty01 .box_body_left .text {
  font-size: 18px;
  color: #23272E;
  letter-spacing: -0.02em;
}
.dashboard .row2 .graph_ty01 .box_body_left .date {
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin: 20px 0;
  letter-spacing: -0.5px;
}
.dashboard .row2 .graph_ty01 .box_body_left strong {
  font-size: 32px;
  letter-spacing: -1px;
}
@media all and (max-width: 480px) {
  .dashboard .row2 .graph_ty01 .box_body_left strong {
    font-size: 29px;
  }
}
.dashboard .row2 .graph_ty01 .box_body_left .figure_wrap {
  margin-top: 5px;
  font-size: 14px;
  letter-spacing: -0.02em;
}
.dashboard .row2 .graph_ty01 .box_body_left .figure_wrap .up {
  padding: 0 8px 0 20px;
  font-weight: 500;
  color: #42A0F0;
  background: url(../img/icon/arrow_up_blue.svg) no-repeat left center;
}
.dashboard .row3 .table_wrap {
  padding: 22px 18px 40px 18px;
  margin-bottom: 0;
}
.dashboard .row3 .table_wrap .table {
  overflow: hidden;
  border-radius: 10px;
}
.dashboard .row3 .table_wrap .id {
  width: 160px;
}
.dashboard .row3 .table_wrap .name, .dashboard .row3 .table_wrap .gender {
  width: 120px;
}
@media all and (max-width: 1440px) {
  .dashboard .row3 .table_wrap {
    margin-bottom: 26px;
  }
}
@media all and (max-width: 991px) {
  .dashboard .row3 .table_wrap {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
.dashboard .table_wrap {
  width: calc(80% - 26px);
  margin-right: 26px;
  padding: 24px 18px 40px;
}
.dashboard .table_wrap .table_type {
  min-width: 1250px;
  min-height: auto;
  padding-bottom: 0;
}
.dashboard .table_wrap .table_type .table thead {
  border: none;
}
.dashboard .table_wrap .table_type .table th {
  font-size: 16px;
}
@media all and (max-width: 991px) {
  .dashboard .table_wrap .table_type .table th {
    font-size: 15px;
  }
}
.dashboard .table_wrap .table_type .table td {
  font-size: 14px;
}
@media all and (max-width: 1440px) {
  .dashboard .table_wrap {
    width: 100%;
    margin-right: 0;
  }
}
.dashboard .bar_graph {
  width: 20%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  /*justify-content:initial;*/
  margin-bottom: 0;
}
.dashboard .bar_graph h4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.dashboard .bar_graph .tit {
  margin-bottom: 0;
}
@media all and (max-width: 1440px) {
  .dashboard .bar_graph {
    width: 100%;
  }
}

.ch_list_detail .arrow_right {
  width: 18px;
  margin-left: 8px;
  vertical-align: sub;
}
.ch_list_detail .banner_table th {
  border-top: none;
}
.ch_list_detail .banner_table img {
  width: 50%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.ch_list_detail .table th.member {
  cursor: pointer;
}

.ch_list_modal {
  padding: 35px 20px;
}
.ch_list_modal .filter_wrap {
  padding: 0;
}
.ch_list_modal .table_wrap {
  max-height: 640px;
  height: 100vh;
  margin: 20px 0;
  overflow: auto;
}
.ch_list_modal .table_wrap .table {
  min-width: 600px;
  border: 1px solid #EBEFF2;
}
.ch_list_modal .table_wrap .table tr {
  height: 55px;
}
.ch_list_modal .table_wrap .table .main_tit {
  border-bottom: 1px solid #EBEFF2;
}
.ch_list_modal #pagination {
  bottom: 80px;
  left: 0;
}
.ch_list_modal .foot_btn_wrap {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding-top: 60px;
}

.ch_add .table_wrap.line {
  border-bottom: none;
}
.ch_add .box_ty01 {
  margin-bottom: 14px;
}
.ch_add .box_ty01 .write_type {
  max-width: none;
  padding: 0;
}
.ch_add .box_ty01 .write_type .wirte_area {
  padding: 28px;
}
@media all and (max-width: 1280px) {
  .ch_add .box_ty01 .write_type .wirte_area {
    padding: 18px;
  }
}
.ch_add .box_ty01 .write_type .plus_area {
  counter-reset: number;
}
.ch_add .box_ty01 .write_type .plus_area .flex_left, .ch_add .box_ty01 .write_type .plus_area .flex_right {
  max-width: 596px;
}
@media all and (max-width: 1200px) {
  .ch_add .box_ty01 .write_type .plus_area .flex_left, .ch_add .box_ty01 .write_type .plus_area .flex_right {
    max-width: 100%;
  }
}
.ch_add .box_ty01 .write_type .plus_area .flex_box label {
  max-width: 194px;
}
.ch_add .box_ty01 .write_type .plus_area .plus_tit {
  padding: 28px;
  margin-bottom: 0;
  border-bottom: 1px solid #EBEFF2;
}
.ch_add .box_ty01 .write_type .plus_area .plus_tit .flex_left {
  max-width: 650px;
}
@media all and (max-width: 1200px) {
  .ch_add .box_ty01 .write_type .plus_area .plus_tit .flex_left {
    max-width: 100%;
  }
}
@media all and (max-width: 1280px) {
  .ch_add .box_ty01 .write_type .plus_area .plus_tit {
    padding: 18px;
  }
}
.ch_add .box_ty01 .write_type .stamp_policy .flex_right, .ch_add .box_ty01 .write_type .point_pay .flex_right {
  max-width: 650px;
}
.ch_add .box_ty01 .write_type .stamp_policy .flex_right label, .ch_add .box_ty01 .write_type .stamp_policy .flex_right .label, .ch_add .box_ty01 .write_type .point_pay .flex_right label, .ch_add .box_ty01 .write_type .point_pay .flex_right .label {
  max-width: 248px;
}
@media all and (max-width: 1440px) {
  .ch_add .box_ty01 .write_type .stamp_policy .flex_right label, .ch_add .box_ty01 .write_type .stamp_policy .flex_right .label, .ch_add .box_ty01 .write_type .point_pay .flex_right label, .ch_add .box_ty01 .write_type .point_pay .flex_right .label {
    max-width: 200px;
  }
}
@media all and (max-width: 1200px) {
  .ch_add .box_ty01 .write_type .stamp_policy .flex_right, .ch_add .box_ty01 .write_type .point_pay .flex_right {
    max-width: 100%;
  }
}
.ch_add .flex_left {
  margin-right: 11%;
}
@media all and (max-width: 1440px) {
  .ch_add .flex_left {
    margin-right: 5%;
  }
}
.ch_add .flex_left.mr12 {
  margin-right: 11.5%;
}
@media all and (max-width: 1440px) {
  .ch_add .flex_left.mr12 {
    margin-right: 5%;
  }
}
.ch_add .input_ty02 input {
  height: 48px;
  line-height: 48px;
  border-radius: 8px;
}
.ch_add .input_ty02 input:disabled {
  background: #F4F4F4;
}
.ch_add .input_ty02::before {
  top: 15px;
}
.ch_add .radio_group .radio_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.ch_add .radio_group .radio_wrap .check_type.radio {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin: 0;
}
.ch_add .radio_group .radio_wrap .check_type.radio label {
  display: block;
  margin: 0;
}
.ch_add .radio_group .radio_wrap .check_type.radio label span::before {
  vertical-align: bottom;
}
.ch_add .radio_group .radio_wrap .check_type.radio:last-child {
  -webkit-box-flex: initial;
      -ms-flex: initial;
          flex: initial;
}
@media all and (max-width: 1200px) {
  .ch_add .radio_group .radio_wrap .check_type.radio {
    -webkit-box-flex: 0.2;
        -ms-flex: 0.2;
            flex: 0.2;
  }
}
@media all and (max-width: 768px) {
  .ch_add .radio_group .radio_wrap .check_type.radio {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
}
.ch_add .icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  -o-object-fit: contain;
     object-fit: contain;
}
.ch_add .icon.click {
  cursor: pointer;
}
.ch_add .numBox {
  position: relative;
  padding: 28px;
}
.ch_add .numBox:nth-child(odd) {
  background: #FBFCFC;
}
@media all and (max-width: 1850px) {
  .ch_add .numBox {
    padding: 28px 150px 28px 82px;
  }
}
@media all and (max-width: 1280px) {
  .ch_add .numBox {
    padding: 18px 150px 18px 82px;
  }
}
@media all and (max-width: 1200px) {
  .ch_add .numBox {
    padding-right: 100px;
  }
}
@media all and (max-width: 768px) {
  .ch_add .numBox {
    padding-right: 28px;
    padding-left: 65px;
  }
}
.ch_add .btn_del {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  right: 6%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: url(../img/icon/trash_gray.svg) no-repeat center, #fff;
  border: 1px solid var(--color-lightGray);
  border-radius: 5px;
}
@media all and (max-width: 768px) {
  .ch_add .btn_del {
    width: 26px;
    height: 26px;
    top: 20px;
    right: auto;
    left: 20px;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    background-size: 15px;
  }
}
.ch_add .banner_table img {
  width: 50%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.ch_add .banner_table .select_input input {
  border: none;
}
.ch_add .banner_table tr {
  height: 90px;
}
.ch_add .banner_table td {
  position: relative;
}
.ch_add .banner_table .icon {
  width: 28px;
  height: 28px;
}
.ch_add .banner_table .btn_del {
  width: 25px;
  height: 25px;
  display: block;
  position: static;
  -webkit-transform: translate(0);
          transform: translate(0);
  margin: 5px auto 0;
  background-size: 15px;
}

.banner_ranking .table_tit {
  padding: 20px;
  font-weight: 500;
}
.banner_ranking .table_tit .btn_plus {
  padding-left: 10px;
}
.banner_ranking .banner_img img {
  width: 50%;
}
.banner_ranking .btn_arrow {
  width: 28px;
  height: 28px;
  margin: 0 2px;
}
.banner_ranking .btn_arrow img {
  -o-object-fit: contain;
     object-fit: contain;
}
.banner_ranking .btn_arrow.btn_down {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.banner_ranking tr:last-child .btn_down {
  display: none;
}
.banner_ranking tr:first-child .btn_up {
  display: none;
}

.banner_ranking_modal {
  max-width: 1580px;
  padding: 35px 20px;
}
.banner_ranking_modal .table_wrap {
  max-height: 500px;
  overflow-y: auto;
}
.banner_ranking_modal .table_wrap th {
  line-height: 1.1;
  font-size: 16px;
  font-weight: 400;
  color: #000;
}
.banner_ranking_modal .table_wrap td {
  line-height: 1.2;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-lightGray);
}
.banner_ranking_modal .modal_tit {
  padding-bottom: 20px;
  font-weight: 500;
}
.banner_ranking_modal .table {
  min-width: 500px;
  border: 1px solid #EBEFF2;
}
.banner_ranking_modal .button_wrap {
  margin-top: 20px;
  text-align: center;
}
.banner_ranking_modal .button_wrap .btn_ty01 {
  padding-left: 50px;
  padding-right: 50px;
  margin: 0 5px;
}

.banner_setting .select_input.input_ty02:nth-of-type(2) .select_box {
  min-width: 270px;
}
.banner_setting .basic .check_type.radio label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.banner_setting .basic .check_type.radio label span {
  width: 24px;
  height: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0;
}
.banner_setting .basic .check_type.radio label span::before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin-right: 0;
}
.banner_setting .basic .check_type.radio label span::after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.banner_setting .basic .check_type.radio span::before {
  margin-right: 0;
}
@media all and (max-width: 991px) {
  .banner_setting .select_input_wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.add_type {
  padding: 20px;
}
.add_type .table {
  border: 1px solid #EBEFF2;
}
.add_type .date_input {
  width: 150px;
}
.add_type .date_input input {
  font-size: 16px;
}
.add_type .select_input {
  text-align: left;
}
.add_type .input_ty02 input {
  text-align: left;
}
.add_type .btn_wrap {
  margin-top: 8px;
}
.add_type .btn_wrap .btn_view {
  margin-left: 5px;
}
.add_type .foot_btn_wrap {
  padding: 20px 0;
}
.add_type input[type=file] {
  display: none;
}

.size_info {
  padding: 20px;
  margin: 0 0 50px;
  background: #f8f8f8;
  border-radius: 5px;
}
.size_info .size_tit {
  position: relative;
  font-size: 16px;
  font-weight: 500;
  padding-left: 25px;
  margin-bottom: 30px;
}
.size_info .size_tit::before {
  content: "";
  width: 18px;
  height: 18px;
  position: absolute;
  left: 0;
  top: 3px;
  background: url(../img/icon/notice.svg) no-repeat;
}
@media all and (max-width: 768px) {
  .size_info .size_tit {
    margin-bottom: 15px;
  }
}
.size_info ul li {
  width: 49%;
  display: inline-block;
  margin: 0 10px 5px 0;
  font-size: 15px;
}
@media all and (max-width: 1200px) {
  .size_info ul li {
    width: 100%;
  }
}
@media all and (max-width: 768px) {
  .size_info ul li {
    font-size: 13px;
    text-indent: -14px;
  }
}
@media all and (max-width: 768px) {
  .size_info {
    margin: 0 0 25px;
    padding-left: 32px;
  }
}

.banner_setting .radio_group,
.popup_setting .radio_group,
.news .radio_group,
.tip .radio_group,
.faq .radio_group {
  margin: 0;
}
.banner_setting .radio_group .radio_wrap,
.popup_setting .radio_group .radio_wrap,
.news .radio_group .radio_wrap,
.tip .radio_group .radio_wrap,
.faq .radio_group .radio_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.banner_setting .radio_group .radio_wrap .check_type.radio,
.popup_setting .radio_group .radio_wrap .check_type.radio,
.news .radio_group .radio_wrap .check_type.radio,
.tip .radio_group .radio_wrap .check_type.radio,
.faq .radio_group .radio_wrap .check_type.radio {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin: 0;
}
@media all and (max-width: 768px) {
  .banner_setting .radio_group .radio_wrap .check_type.radio label span,
  .popup_setting .radio_group .radio_wrap .check_type.radio label span,
  .news .radio_group .radio_wrap .check_type.radio label span,
  .tip .radio_group .radio_wrap .check_type.radio label span,
  .faq .radio_group .radio_wrap .check_type.radio label span {
    padding-left: 30px;
  }
}

@media all and (max-width: 768px) {
  .calc_list .filter_wrap .select_input_wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.accumulated .table {
  min-height: 450px;
}
.accumulated .table .date_input input {
  padding: 0 30px 0 10px;
  background-position: right 4px center;
  border: none;
}
.accumulated .table .wave {
  margin: 0 10px;
}

.point .point_input input {
  margin-right: 10px;
}
.point .number input {
  width: 80px;
  padding: 10px;
  margin: 0 10px;
}

@media all and (max-width: 768px) {
  .qna .filter_wrap .select_input_wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.product .pd_img {
  position: relative;
}
.product .pd_img .img {
  width: 60px;
  height: 100px;
  -o-object-fit: cover;
     object-fit: cover;
}
.product .pd_img .btn_edit {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 15px;
  bottom: -5px;
  background: url(../img/icon/edit.svg) no-repeat center/16px, var(--color-green);
  border-radius: 5px;
}
.product .radio_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  row-gap: 5px;
  margin-left: 10px;
}
@media all and (max-width: 768px) {
  .product .radio_wrap {
    row-gap: 0;
  }
}
.product .radio_wrap .check_type {
  margin-bottom: 5px;
}
.product .radio_wrap .check_type:last-child {
  margin-bottom: 0;
}

#search {
  padding: 35px 20px;
}
#search .search_list {
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-weight: 500;
  border-bottom: 1px solid #E2E8F0;
}
#search .search_list:last-child {
  border-bottom: none;
}
#search .search_list li:not(.sh_tit) {
  margin-bottom: 5px;
}
@media all and (max-width: 768px) {
  #search .search_list li:not(.sh_tit) {
    font-size: 14px;
  }
}
#search .sh_tit {
  margin-bottom: 15px;
  font-size: 18px;
}
@media all and (max-width: 768px) {
  #search .sh_tit {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
#search .match_text {
  margin: 3px;
  color: var(--color-green);
}
#search .c_gray {
  margin-left: 10px;
  font-size: 14px;
  font-weight: normal;
  color: var(--color-gray);
}
#search .btn_more {
  padding-right: 20px;
  margin: 20px 0 0;
  background: url(../img/icon/angle_thin_right_g.svg) no-repeat right center;
}